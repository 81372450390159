<template>
	<q-card>
		<q-card-section>
			<div class="text-h5 text-left">Add new template</div>
		</q-card-section>
		<q-card-section>
			<div class="text-left">
				<sftp-template-settings
					v-if="ready"
					:template="{ template_settings }"
					@update="update"
				/>
				<import-settings
					:import_settings="template_settings"
					:table_column_options="table_column_options"
					:file="
						template_settings.file_headers
							? {
									file_headers: template_settings.file_headers.headers,
									file_data: template_settings.file_data,
									file_name: template_settings.file_name,
							  }
							: null
					"
					:delete_button="false"
					@fileUpdate="analyzeFile"
					@update="update"
					@save="create"
					@cancel="$router.push({ name: 'Sftp List' })"
				/>
			</div>
		</q-card-section>
	</q-card>
</template>
<script>
import SftpTemplateSettings from '@/components/datasets/edit/sftp/SftpTemplateSettings'
import ImportSettings from '@/components/datasets/edit/sftp/ImportSettings'
import helpers from '@/utils/helpers.js'
import DatasetsAPI from '@/services/api/Datasets.js'
import { mapActions } from 'vuex'

export default {
	name: 'Add',
	components: {
		SftpTemplateSettings,
		ImportSettings,
	},
	data() {
		return {
			ready: false,
			template_settings: {
				customer_group: '',
				external_id: 'true',
				gpg_encrypted: 'false',
				import_by_column: '',
				keep_admins_from_demoting: 'true',
				allow_new_field_options_to_be_created: false,
				import_type: '',
				template_name: {},
			},
			file: null,
			table_column_options: [],
		}
	},
	methods: {
		...mapActions(['saveTemplate', 'fetchUser']),
		async analyzeFile(val) {
			this.file = val
			var form_data = new FormData()
			form_data.append('file', val)
			const response = await DatasetsAPI.analyzeFile(form_data, null, true)
			this.template_settings = {
				...this.template_settings,
				...{
					file_headers: response.file_headers,
					file_data: response.file_data,
				},
			}
		},
		async buildTableColumnOptions() {
			this.table_column_options = await helpers.buildSelectionListOptions({
				import_type: this.template_settings.import_type,
				column: this.template_settings.import_by_column,
				cg: this.template_settings.customer_group,
				external_id: this.template_settings.external_id,
				gpg_encrypted: this.template_settings.gpg_encrypted,
			})
		},
		async update(settings) {
			this.template_settings = settings
			await this.buildTableColumnOptions()
		},
		async create(rules) {
			let form_data = helpers.buildTemplateFormData({
				template_settings: this.template_settings,
				rules: rules,
				index: this.$route.params.charIndex,
				file: this.file,
			})
			const response = await this.saveTemplate(form_data)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			this.$router.push({ name: 'Sftp List' })
			await this.fetchUser()
		},
	},
	async created() {
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		this.template_settings.template_name.label =
			'template_' + alphabet[this.$route.params.charIndex]
		this.template_settings.template_name.name =
			'template_' + alphabet[this.$route.params.charIndex].toLowerCase()
		await this.buildTableColumnOptions()
		this.ready = true
	},
}
</script>

<style></style>
