<template>
	<div>
		<div class="row">
			<div class="col-12 col-sm-6 q-px-sm q-my-sm">
				<q-input
					v-model="template_name"
					outlined
					label="Name"
					hint="Name your template"
					class="q-my-sm"
					:error="!template_name"
					error-message="This field is required."
				/>
			</div>
			<div class="col-12 col-sm-6 q-px-sm q-my-sm">
				<q-field
					outlined
					label="Directory"
					stack-label
					hint="SFTP server directory"
					class="q-my-sm"
				>
					<template v-slot:control>
						<div class="self-center full-width no-outline" tabindex="0">
							{{ sftp_directory }}
						</div>
					</template>
				</q-field>
			</div>
		</div>
		<div class="row q-my-sm">
			<div class="col-sm-6 q-my-sm">
				<q-toggle v-model="gpg_encrypt" left-label>
					<template
						><strong
							>Do you wish to encrypt your file using PGP?</strong
						></template
					>
				</q-toggle>
			</div>
			<div class="col-sm-6 q-my-sm">
				<q-btn
					v-if="gpg_encrypt"
					color="primary"
					icon="download"
					label="Get key"
					outline
					@click="getPublicKey"
				>
					<q-tooltip class="bg-accent"
						>Use this public key to encrypt your files</q-tooltip
					>
				</q-btn>
			</div>
		</div>
	</div>
</template>

<script>
import helpers from '@/utils/helpers.js'
import DatasetsAPI from '@/services/api/Datasets.js'

export default {
	name: 'SftpTemplateSettings',
	props: {
		template: Object,
	},
	computed: {
		template_name: {
			get() {
				return this.template.template_settings.template_name.label
			},
			set(val) {
				this.update({
					property: 'template_name',
					value: {
						name: val,
						label: val,
					},
				})
			},
		},
		sftp_directory() {
			return this.template.template_settings.template_name.name
		},
		gpg_encrypt: {
			get() {
				if (this.template.template_settings.gpg_encrypted && this.template.template_settings.gpg_encrypted != 'undefined') {
					return helpers.strToBool(this.template.template_settings.gpg_encrypted)
				} else {
					return false
				}
			},
			set(val) {
				this.update({
					property: 'gpg_encrypted',
					value: val,
				})
			},
		},
	},
	methods: {
		async getPublicKey() {
			const response = await DatasetsAPI.getGpgPublicKey()
			let blob = new Blob([response['file']], { type: response['type'] })

			if (window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveBlob(blob, response['name'])
			} else {
				var url = URL.createObjectURL(blob)

				var a = document.createElement('a')
				a.setAttribute('href', url)
				a.setAttribute('download', response['name'])

				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			}
		},
		update(obj) {
			let data = JSON.parse(JSON.stringify(this.template.template_settings))
			data = {...data, ...data.request_data}
			delete data.request_data
			data[obj.property] = obj.value
			this.$emit('update', data)
		},
	},
}
</script>

<style></style>
