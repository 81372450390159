var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-card',[_c('q-card-section',[_c('div',{staticClass:"text-h5 text-left"},[_vm._v("Add new template")])]),_c('q-card-section',[_c('div',{staticClass:"text-left"},[(_vm.ready)?_c('sftp-template-settings',{attrs:{"template":{ template_settings: _vm.template_settings }},on:{"update":_vm.update}}):_vm._e(),_c('import-settings',{attrs:{"import_settings":_vm.template_settings,"table_column_options":_vm.table_column_options,"file":_vm.template_settings.file_headers
						? {
								file_headers: _vm.template_settings.file_headers.headers,
								file_data: _vm.template_settings.file_data,
								file_name: _vm.template_settings.file_name,
						  }
						: null,"delete_button":false},on:{"fileUpdate":_vm.analyzeFile,"update":_vm.update,"save":_vm.create,"cancel":function($event){return _vm.$router.push({ name: 'Sftp List' })}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }